;( function() {

	"use strict";

	var map = new APP.Map( $( ".app-map-wrapper" ).get(0) );
	
	$( ".entities-table" ).stupidtable();

	var $trs = $( ".data-row" );
	$trs.on( "click", function( evt ) {
		evt.preventDefault();
		var url = $( this ).attr( "data-url" );
		window.location = url;
	} );

	//pick up data from dom
	var data = [];
	$.each( $trs, function( i, v ) {
			
		var $tr = $( v ),
			lat =  $tr.attr( "data-lat" ),
			lng = $tr.attr( "data-lng" ),
			url = $tr.attr( "data-url" ),
			geom = $tr.attr( "data-geom" ),
			typeId = $tr.attr( "data-type-id" ),
			index = $tr.find( ".index-value" ).text(),
			size = $tr.find( ".size-value" ).text(),
			women = $tr.find( ".women-value" ).text(),
			men = $tr.find( ".men-value" ).text();
			
		data.push( {
			"id": $tr.attr( "data-id" ),
			"title": $tr.attr( "data-name" ),
			"lat": parseFloat( lat ),
			"lng": parseFloat( lng ),
			"geom": geom,
			"type": typeId,
			"index": parseFloat( index ),
			"size": parseInt( size, 10 ),
			"women": parseInt( women, 10 ),
			"men": parseInt( men, 10 ),
			"url": url
		} );
		
	} );

	map.addData( data );
	
})();