;( function() {

	"use strict";

	APP.Map = function( el ) {

		this.maxPos = 14;
		this.minPos = 3;
		this.maxValue = 20;
		this.minValue = 5;

		this.grayTypeId = "APP.Views.Components.GrayTypeMap";
		this.grayScaleStyle = [
  {
    "stylers": [
      { "saturation": -100 }
    ]
  },{
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      { "visibility": "off" }
    ]
  },{
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      { "visibility": "off" }
    ]
  },{
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [
      { "lightness": -100 }
    ]
  },{
    "featureType": "landscape.man_made",
    "elementType": "geometry",
    "stylers": [
      { "lightness": -55 }
    ]
  },{
    "featureType": "landscape.natural",
    "elementType": "geometry.fill",
    "stylers": [
      { "lightness": -58 }
    ]
  },{
    "featureType": "road",
    "elementType": "geometry.stroke",
    "stylers": [
      { "visibility": "off" }
    ]
  },{
    "featureType": "poi",
    "stylers": [
      { "lightness": -51 }
    ]
  },{
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      { "lightness": -100 }
    ]
  },{
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      { "lightness": -75 }
    ]
  },{
    "featureType": "transit.station",
    "stylers": [
      { "visibility": "off" }
    ]
  },{
    "featureType": "transit.line",
    "elementType": "geometry.fill",
    "stylers": [
      { "lightness": -83 }
    ]
  },{
    "featureType": "transit",
    "elementType": "geometry.stroke",
    "stylers": [
      { "visibility": "off" }
    ]
  },{
    "featureType": "administrative",
    "elementType": "labels.text.stroke",
    "stylers": [
      { "weight": 5.7 },
      { "lightness": -57 }
    ]
  },{
    "featureType": "water",
    "elementType": "labels.text.stroke",
    "stylers": [
      { "visibility": "off" }
    ]
  },{
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      { "lightness": 56 }
    ]
  },{
    "featureType": "landscape",
    "elementType": "labels",
    "stylers": [
      { "visibility": "off" }
    ]
  },{
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [
      { "visibility": "on" },
      { "lightness": -100 }
    ]
  },{
    "featureType": "administrative.locality",
    "elementType": "labels.icon",
    "stylers": [
      { "visibility": "on" },
      { "lightness": -54 }
    ]
  },{
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [
      { "lightness": 1 },
      { "visibility": "on" }
    ]
  },{
    "featureType": "road",
    "elementType": "labels.text.stroke",
    "stylers": [
      { "weight": 5.5 },
      { "lightness": -60 }
    ]
  },{
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      { "lightness": -100 }
    ]
  }
];

		this.el = el;
		this.$win = $( window );
		this.$popup = $( ".map-popup" );
		this.$legend = $( ".map-legend" );
		this.$legendLi = this.$legend.find( "li" );
		this.$legendTooltip = $( ".map-legend-tooltip" );
		this.$legendTooltipP = this.$legendTooltip.find( "p" );
		this.$typeSwitchNavigation = $( ".type-switch-navigation" );
		this.$timeSelect = $( ".time-select" );
		this.$navigation = $( ".main-navigation" );

		this.map = null;
		this.overlay = null;
		this.markers = [];

		google.maps.event.addDomListener( window, "load", $.proxy( this.init, this ) );
		this.$win.on( "resize", $.proxy( this.onResize, this ) );
		this.onResize();

	};

	APP.Map.prototype = {

		init: function() {
			
			var mapOptions = {
				center: { lat: 50, lng: 15},
				zoom: 8,
				maxZoom: 16,
				minZoom: 7,
				disableDefaultUI: true,
				zoomControl: true,
				zoomControlOptions: {
					style: google.maps.ZoomControlStyle.SMALL,
					position: google.maps.ControlPosition.LEFT_TOP
				}
			};
			this.map = new google.maps.Map( this.el, mapOptions );
			
			//set style
			var grayStyledMap = new google.maps.StyledMapType(this.grayScaleStyle,{name: "Gray Styled Map"});
			this.map.mapTypes.set( this.grayTypeId, grayStyledMap);
			this.map.setMapTypeId( this.grayTypeId );

			this.overlay = new google.maps.OverlayView();
			this.overlay.draw = function() {};
			this.overlay.setMap( this.map );

			this.$legendLi.on( "mouseover", $.proxy( this.onLegendOver, this ) );
			this.$legendLi.on( "mouseout", $.proxy( this.onLegendOut, this ) );

			//do we have data already assigned
			if( this.data ) {
				this.addData( this.data );
			}

		},

		addData: function( data ) {

			this.clearData();
			this.data = data;

			//is map inited
			if( !this.map ) {
				return;
			}

			var that = this;
			$.each( data, function( i, d ) {
				if( d && d.size && d.size > 0 ) {
					that.maxValue = Math.max( that.maxValue, d.size );
					that.minValue = Math.min( that.minValue, d.size );
				}
			} );

			var wkt = new Wkt.Wkt(),
				len = data.length,
				bounds = new google.maps.LatLngBounds();

			while( --len >= 0 ) {

				var featureData = data[ len ],
					features = [], feature, obj;
				
				if( featureData.geom ) {

					//is polygon or multipolygon
					wkt.read( featureData.geom );
					obj = wkt.toObject();

					var polygon;
					if( obj instanceof Array ) {
						
						_.each( obj, function( singleObj ) {
							polygon = that.addPolygon( singleObj, featureData );
							features.push( polygon );
						} );

					} else if( typeof obj.setOptions === "function" ) {

						features.push( that.addPolygon( obj, featureData ) );

					}
					

				} else if( featureData.lat && featureData.lng ) {

					features.push( that.addMarker( featureData ) );

				}

				if( features.length ) {

					_.each( features, function( feature ) {

						feature.addListener( "click", function( evt ) {
							that.onFeatureClick( this );
						} );
						feature.addListener( "mouseover", function( evt ) {
							that.onFeatureMouseOver( this );
						} );
						feature.addListener( "mouseout", function( evt ) {
							that.onFeatureMouseOut( this );
						} );

						bounds.extend( feature.position );

					} );
					
				}
				
			}

			//if country level, just zoom out
			if( !_.where( data, { type: "1" } ).length ) {
				that.map.fitBounds( bounds );
			} else {
				that.map.setZoom( 7 );
			}

		},

		addPolygon: function( obj, polygonData ) {

			//make sure we have some data for this entity
			var icon = this.computeIcon( polygonData.type, polygonData.index, polygonData.size );
			obj.setOptions( { "fillColor": icon.fillColor, "strokeColor": icon.strokeColor, "fillOpacity": 0.7, "strokeWeight": icon.strokeWeight } );
			obj.id = polygonData.id;
			obj.position = obj.my_getBounds().getCenter();
			obj.title = polygonData.title;
			obj.index = polygonData.index;
			obj.size = polygonData.size;
			obj.women = polygonData.women;
			obj.men = polygonData.men;
			obj.url = polygonData.url;
			obj.setMap( this.map );
			
			//return new entity
			return obj;
			
		},

		addSinglePolygon: function( obj, polygonData ) {

			

		},

		addMarker: function( markerData ) {
			
			var that = this,
				latLng = new google.maps.LatLng( parseFloat( markerData.lat ), parseFloat( markerData .lng ) ),
				icon = this.computeIcon( markerData.type, markerData.index, markerData.size ),
				marker = new google.maps.Marker({
					id: markerData.id,
					position: latLng,
					map: that.map,
					title: markerData.title,
					icon: icon,
					index: markerData.index,
					size: markerData.size,
					zIndex: 1000-markerData.size,
					women: markerData.women,
					men: markerData.men,
					url: markerData.url
				});

			return marker;
			
		},

		clearData: function() {

			var len = this.markers.length;
			while( --len >= 0 ) {
				var marker = this.marker[ i ];
				marker.setMap( null );
			}
			this.markers = [];

		},

		onFeatureClick: function( marker ) {

			var url = marker.url;
			if( url ) {
				window.location = url;
			}
			
		},

		onFeatureMouseOver: function( marker ) {

			var projection = this.overlay.getProjection(),
				pixel = projection.fromLatLngToContainerPixel( marker.position );
			
			if( marker.getIcon ) {
				var icon = marker.getIcon();
				icon.strokeWeight = 1;
				marker.setIcon( icon );
			} else {
				marker.setOptions( { "strokeWeight": 1 } );
			}
			this.displayPopup( marker, pixel );


		},

		onFeatureMouseOut: function( marker ) {

			if( marker.getIcon ) {
				var icon = marker.getIcon();
				icon.strokeWeight = 0.1;
				marker.setIcon( icon );
			} else {
				marker.setOptions( { "strokeWeight": 0.1 } );
			}
			this.$popup.hide();

		},

		displayPopup: function( data, pixel ) {
			
			//update data
			this.$popup.find( "h2" ).text( data.title );
			var sizeString = ( !isNaN( data.size ) )? data.size: 0;
			this.$popup.find( ".map-popup-size span" ).text( sizeString + " osob" );
			var index = ( data.index > 0 )? data.index.toFixed( 2 ): 0;
			this.$popup.find( ".map-popup-index" ).text( index );
			
			var womenRatio = data.women/data.size;
			if( !isNaN( womenRatio ) ) {
				womenRatio = (data.women/data.size)*100 + "%";
			} else {
				womenRatio = 0;
			}
			var menRatio = data.men/data.size;
			if( !isNaN( menRatio ) ) {
				menRatio = (data.men/data.size)*100 + "%";
			} else {
				menRatio = 0;
			}
			this.$popup.find( ".barometer-item-women" ).width( womenRatio );
			this.$popup.find( ".barometer-item-men" ).width( menRatio );
			
			//display, so that we have valid dimensions	
			this.$popup.show();

			var positionLeft = pixel.x - this.$popup.outerWidth()/2,
				positionTop = pixel.y - this.$popup.outerHeight()-40;

			//position popup
			this.$popup.css( { "top": positionTop, "left": positionLeft } );
			
		},

		computeIcon: function( type, index, size ) {
			
			//generate icon
			/*var value = (1-index) * 0xFF | 0,
				grayscale = (value << 16) | (value << 8) | value,
				color = '#' + grayscale.toString(16);

			if( isNaN( index ) ) {
				color = "#fff";
			}*/
			
			var color = "";
			if( size > 0 ) {
				if( index < .15 ) {
					color = "#61b2f0";
				} else if( index >= .15 && index < .30 ) {
					color = "#97ccf5";
				} else if( index >= .30 && index < .45 ) {
					color = "#cbe6fa";
				} else if( index >= .45 && index < .55 ) {
					color = "#ffffff";
				} else if( index >= .55 && index < .70 ) {
					color = "#facdcd";
				} else if( index >= .70 && index < .85 ) {
					color = "#f59c9c";
				} else {
					color = "#f06868";
				}
			} else {
				//there's no data for given feature
				color = "#cccccc";
			}

			var iconScale = this.computeScale( size );
			
			return {
				path: google.maps.SymbolPath.CIRCLE,
				fillColor: color,
				fillOpacity: ( isNaN( index ) )? 0.5: 1,
				strokeColor: "#111",
				strokeOpacity: ( isNaN( index ) )? 0.5: 1,
				strokeWeight: 0.1,
				scale: iconScale
			};

			var icon = Global.rootUrl + "/assets/imgs/google-map-markers/brown_MarkerA.png";
			switch( type ) {
				case "1":
					icon = Global.rootUrl + "/assets/imgs/google-map-markers/blue_MarkerA.png";
					break;
				case "2":
					icon = Global.rootUrl + "/assets/imgs/google-map-markers/red_MarkerA.png";
					break;
				case "3":
					icon = Global.rootUrl + "/assets/imgs/google-map-markers/yellow_MarkerA.png";
					break;
				case "4":
					icon = Global.rootUrl + "/assets/imgs/google-map-markers/darkgreen_MarkerA.png";
					break;
			}
			return icon;
		},

		computeScale: function( value ) {

			var maxValue = this.maxValue,
				minValue = this.minValue,
				maxSize = this.maxPos,
				minSize = this.minPos;

			var valuePos = ( value - minValue ) / ( maxValue - minValue ),
				size = valuePos * ( maxSize-minSize ) + minSize;

			/* logarithm 
			// position will be between 0 and 100
			  var minp = 0;
			  var maxp = 100;

			  // The result should be between 100 an 10000000
			  var minv = Math.log(100);
			  var maxv = Math.log(10000000);

			  // calculate adjustment factor
			  var scale = (maxv-minv) / (maxp-minp);

			  return Math.exp(minv + scale*(position-minp));*/

			return size;

		},

		onLegendOver: function( evt ) {
		
			//show only on valid target
			var $target = $( evt.target );
			this.$legendTooltip.show();
			this.$legendTooltipP.text( $target.attr( "data-label" ) );
			this.$legendTooltip.css( { "left": $target.offset().left-this.$legendTooltip.width()/2+$target.width()/2, "top":  $target.offset().top - 10 } );
				
		},

		onLegendOut: function( evt ) {
			this.$legendTooltip.hide();
		},

		onResize: function() {
			var $container = $( ".app-header .container" ),
				containerWidth = $container.width(),
				winWidth = this.$win.width(),
				diff = winWidth - containerWidth;

			this.$legend.css( "left", diff/2 );
			this.$typeSwitchNavigation.css( "right", diff/2 );
			this.$timeSelect.css( "right", diff/2 );
			this.$navigation.css( "right", diff/2 );
		}

	};

})();

/**
* extend google maps api with missing method
* http://stackoverflow.com/questions/3081021/how-to-get-the-center-of-a-polygon-in-google-maps-v3
**/
google.maps.Polygon.prototype.my_getBounds = function(){
	var bounds = new google.maps.LatLngBounds();
	this.getPath().forEach(function(element,index){bounds.extend(element);});
	return bounds;
}
