;( function() {

	"use strict";

	var $navBtn = $( ".btn-navbar" ),
		$navMenu = $( ".nav-collapse" ),
		$navLinks = $navMenu.find( "a" );

	$navBtn.on( "click", function( evt ) {

		$navMenu.slideToggle();
		return;
		
		if( $navMenu.hasClass( "in" ) ) {
			//hiding
			$navMenu.removeClass( "in" );
			$navMenu.css( "height", "0" );
		} else {
			//appearing
			$navMenu.addClass( "in" );
			$navMenu.css( "height", "auto" );
		}

	} );

	$navLinks.on( "click", function( evt ) {

		var $a = $( this ),
			$submenu = $a.siblings( "ul" );

		if( $submenu.length ) {
			evt.preventDefault();
			$submenu.slideToggle();
		} else {
			//doesn't have submenu, do nothing and treat this as normal link click
		}
		
		


	} );

})();