;( function() {

	"use strict";

	var $win, $header, $searchWrapper, $searchInputWrapper, $searchInput, $searchResults, $lis, $searchIcon, $preloaderIcon, $clearIcon, $keyDownHandler;

	var initialize = function () {

		$win = $( "window" );
		$header = $( ".app-header" );
		$searchWrapper = $( ".search-wrapper" );
		$searchInputWrapper = $searchWrapper.find( ".search-input-wrapper" );
		$searchInput = $searchWrapper.find( "[name=search]" );
		$searchResults = $searchWrapper.find( ".search-results" );
		$searchIcon = $searchWrapper.find( ".fa-search" );
		$preloaderIcon = $searchWrapper.find( ".fa-spinner" );
		$clearIcon = $searchWrapper.find( ".fa-times" );
		
		$searchIcon.on( "click", onSearchClick );
		$searchInput.on( "input", onSearchInput );
		$searchInput.on( "focusin", onSearchFocusIn );
		$searchInput.on( "focusout", onSearchFocusOut );
		$clearIcon.on( "click", onClearClick );

		//prevent submitting on enter
		$searchInputWrapper.on( "keypress", function(evt) {

			if( evt.keyCode == 13 ) {
				return false;
			}

		});

	};

	var onSearchClick = function( evt ) {
		
		evt.preventDefault();
		$searchInput.show();
		$searchInput.focus();

		$clearIcon.show();
		$searchIcon.hide();
		$header.addClass( "open" );

	};

	var onSearchInput = function( evt ) {
			
		var $input = $( evt.currentTarget ),
			searchTerm = $input.val();

		if( searchTerm.length >= 2 ) {
			
			$clearIcon.hide();
			$searchIcon.hide();
			$preloaderIcon.show();

			doSearch( searchTerm );

		} else {

			//clear selection
			$searchResults.empty();
			$searchResults.hide();
			
			//$clearIcon.show();
			//$searchIcon.show();

		}

	};

	var onClearClick = function( evt ) {

		$searchInput.hide();
		$clearIcon.hide();
		$searchIcon.show();
		$header.removeClass( "open" );

	};

	var onSearchFetched = function( results ) {

		$clearIcon.show();
		$searchIcon.hide();
		$preloaderIcon.hide();

		$searchResults.empty();
		$searchResults.show();
		
		var htmlString = "";
		_.each( results, function( entities, categoryName ) {
			if( entities.length ) {
				htmlString += "<li><ul class='category-search-results'>";
					htmlString += "<h3>" + categoryName + "</h3>";
					_.each( entities, function( entity ) {
						htmlString += "<li data-url='" + entity.url + "'>" + entity.name + "</li>";
					} );
				htmlString += "</ul></li>";
			}
		} );

		$searchResults.append( $( htmlString ) );
		$lis = $searchResults.find( ".category-search-results li" );
			
		$lis.on( "mousedown", function( evt ) {
			selectItem( $( evt.currentTarget ) );
		} );

	};

	var onSearchFocusIn = function() {
		//show select only if some results
		if( $searchResults.find( "li" ).length ) {
			$searchResults.show();
		}
		$keyDownHandler = $.proxy( onKeyDown, this );
		$win.on( "keydown", $keyDownHandler );
	};

	var onSearchFocusOut = function( evt ) {
		$searchResults.hide();
		$win.off( "keydown", $keyDownHandler );
	};

	var onKeyDown = function( evt ) {

		if( !$lis || !$lis.length ) {
			return;
		}

		var selectedIndex = $lis.filter( ".selected" ).index(),
			keyCode = evt.keyCode;
			
		if( keyCode === 40 || keyCode === 38 ) {

			if( keyCode === 40 ) {
				selectedIndex++;
				if( selectedIndex >= this.$lis.length ) {
					selectedIndex = 0;
				}
			} else if( keyCode === 38 ) {
				selectedIndex--;
			}

			$lis.removeClass( "selected" );
			$lis.eq( selectedIndex ).addClass( "selected" );
		
		} else if( keyCode === 13 ) {

			//selectItem( this.$lis.eq( selectedIndex ) );
			$searchResults.hide();

		}

	};

	var	onClearBtn = function() {
		$selectVarSearch.val( "" );
		$selectVarSearch.trigger( "input" );
	};

	var selectItem = function( $el ) {

		var time = $searchInputWrapper.attr( "data-time" ),
			url = $el.attr( "data-url" );
		
		if( time ) {
			url += "?" + time;
		}
		window.location = url;

	};

	var doSearch = function( search ) {

		var url = $searchInputWrapper.attr( "action" ),
			type = $searchInputWrapper.find( "[name=type]" ).val();
		
		$.ajax( {
			url: url,
			data: { "s": search, "type": type },
			success: function( response ) {
				if( response && response.data ) {
					onSearchFetched( response.data );
				}
			}
		} );

	};

	initialize();

})();
