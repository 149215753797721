;( function() {

	"use strict";

	var $window = $( window ),
		$appTooltip = $( ".app-tooltip" ),
		$appTooltipP = $appTooltip.find( "p" ),
		$tooltips = $( ".tooltip-hint" );
	
	$tooltips.on( "mouseover", function( evt ) {

		var $this = $( evt.target ),
			offset = $this.offset();
	
		$appTooltipP.text( $this.attr( "title" ) );
		$appTooltip.removeClass( "reverse" );
		$appTooltip.show();

		$appTooltip.css( { "left": offset.left + 25, "top": offset.top + 25 } );

		var toolRightEdge = $appTooltip.offset().left + $appTooltip.width();
		if( toolRightEdge > $window.width() ) {
			$appTooltip.addClass( "reverse" );
			$appTooltip.css( { "left": offset.left - $appTooltip.width() - 5, "top": offset.top + 25 } );
		}
		
	} );

	$tooltips.on( "mouseout", function( evt ) {

		$appTooltip.hide();

	} );

})();