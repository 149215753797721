;( function() {

	"use strict";

	$( "[name='compare-select']" ).on( "change", function() {
		var $this = $( this ),
			redirectUrl = $this.val();
		window.location = redirectUrl;
	} );

} )() ;