;( function() {

	"use strict";

	var $win, $el, $stars, $parent, $tooltip, $tooltipTitle, $tooltipP, elOffsetTop;

	var init = function() {

		$win = $( window );
		$el = $( ".main-barometer" );
		$stars = $el.find( ".barometer-star" );
		$tooltip = $( ".main-barometer-tooltip" );
		$tooltipTitle = $tooltip.find( "h3" );
		$tooltipP = $tooltip.find( "p" );

		//events
		$el.on( "mouseover", onElementOver );
		$stars.on( "mouseover", onElementOver );
		$el.on( "mousemove", onElementMove );
		$stars.on( "mousemove", onElementMove );
		$el.on( "mouseout", onElementOut );
		$stars.on( "mouseout", onElementOut );

	};

	var onElementOver = function( evt ) {
		//show only on valid target
		var $target = $( evt.target );

		if( $target.hasClass( "barometer-item" ) || $target.hasClass( "fa-star" ) ) {
			
			//is compare or normal barometer
			var $compareParent = $target.parents( ".compare-main-barometer" );

			$tooltip.show();
			$target.addClass( "active" );
			$tooltipP.text( $target.attr( "data-tooltip-text" ) );
			
			if( $compareParent.length ) {
				$tooltipTitle.show();
				$tooltipTitle.text( $compareParent.attr( "data-title" ) );
			} else {
				$tooltipTitle.hide();
			}
		
		}
	};

	var onElementMove = function( evt ) {

		var $target = $( evt.target ),
			$parent = $target.parents( ".main-barometer" ),
			elOffsetTop = ( $parent .length )? $parent.offset().top: 0;
		
		//extra offset for little arrow
		elOffsetTop -= 5;

		$tooltip.css( { "left": evt.pageX-$tooltip.width()/2, "top": elOffsetTop+3 } );
		//checkBounds( evt );
	};

	var onElementOut = function( evt ) {
		$tooltip.hide();
		var $target = $( evt.target );
		$target.removeClass( "active" );
	};

	var checkBounds = function( evt ) {
		
		//reset
		$tooltip.removeClass( "reverse" );
		
		var tooltipLeft = parseInt( $tooltip.css( "left" ), 10 ),
			tooltipWidth = parseInt( $tooltip.width(), 10 ),
			tooltipRightEdge = tooltipLeft + tooltipWidth;
		
		if( $win.width() <= tooltipRightEdge ) {
			$tooltip.addClass( "reverse" );
			$tooltip.css( "left", evt.pageX - tooltipWidth );
		}

	};
	
	init();

})();